<template>
  <badge-list :value="categories" rounded />
</template>

<script lang="ts">
import type { PermissionsPreset } from "@/models/permissionsPreset";
import type { WorkflowTemplateCategoriesDefinition } from "@/models/workflows";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import uniq from "lodash/uniq";

interface Props {
  model: PermissionsPreset;
}

export default {
  inheritAttrs: false
};
</script>

<script setup lang="ts">
import BadgeList from "@/components/ui/BadgeList.vue";

const props = defineProps<Props>();
const { getters } = useStore();
const { t } = useI18n();

const workflowCategoriesInfo = computed<WorkflowTemplateCategoriesDefinition[]>(
  () => getters["options/workflowCategoriesInfo"]
);

const categories = computed(() => {
  const categoryIds = props.model.assigned_workflow_templates?.flatMap(
    ({ category_id }) => category_id
  );

  return uniq(categoryIds).map((catId) => {
    const category = workflowCategoriesInfo.value.find(
      ({ id }) => id === catId
    );
    const categoryName = category?.name.toUpperCase() || "";
    if (!categoryName) {
      return "";
    }
    return t(`WORKFLOW.CATEGORIES.${categoryName}.NAME`);
  });
});
</script>
